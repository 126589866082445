import {ACTION_TYPE} from "../actions/globalData";
import  {getTitles,getCountries} from "../../utils/requests"

const initialState = {
    inProgress: false,
    titles: getTitles(),
    mobiles: getCountries(),
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_TITLES_IN_PROGRESS:
            return {
                ...state,
                inProgress: action.payload,
            };
        case ACTION_TYPE.SET_TITLES:
            return {
                ...state,
                titles: action.payload,
            };
        default:
            return state;
    }
}
