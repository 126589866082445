import {createAction} from "../../factory/redux/action";

export const ACTION_TYPE = Object.freeze({
    SET_BUTTON_TEXT:"SET_BUTTON_TEXT",
    SET_BUTTON_SHOW:"SET_BUTTON_SHOW",
    SET_BUTTON_LINK:"SET_BUTTON_LINK"
});

export const SET_BUTTON_SHOW = (payload) => createAction(
    ACTION_TYPE.SET_BUTTON_SHOW,
    payload
);

export const SET_BUTTON_TEXT = (payload) => createAction(
    ACTION_TYPE.SET_BUTTON_TEXT,
    payload
);

export const SET_BUTTON_LINK = (payload) => createAction(
    ACTION_TYPE.SET_BUTTON_LINK,
    payload
);