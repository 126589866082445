import {apiUrls} from "../constants/urls";
import React from "react";
import axios from "axios";
import _ from "lodash";

export function getSimpleRequest(url) {
  let xhr = new XMLHttpRequest();

  xhr.open('GET', url, false);
  xhr.send();

  let response = JSON.parse(xhr.responseText);

  return response;

}

export function postSimpleRequest(url, params) {

  let xhr = new XMLHttpRequest();

  xhr.open('POST', url, false);
  xhr.setRequestHeader('Authorization', sessionStorage.getItem('token'));
  // xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify(params));
  let response;
  if (xhr.status < 300) {
    response = JSON.parse(xhr.responseText);
  } else {
    response = xhr.response;
  }

  return response;
}

export function getRequest(url, params, isSecure, headers) {
  let config = {};
  if (params) {
    config.params = params;
  }
  if (isSecure) {
    config.headers = {
      "Authorization": sessionStorage.getItem('token'),
    };
  }
  if (headers) {
    config.headers = _.merge(config.headers, headers);
  }

  return axios.get(url, config)
    .then((response) => {
      return response;
    });
}

export function postRequest(url, body, isSecure, headers, multi=false) {

  const contentType = multi ? "application/json" : "multipart/form-data";

  let config = {
    headers: {
      "Content-Type": contentType
    }
  };

  if (isSecure) {
    config.headers = {
      "Authorization": sessionStorage.getItem('token'),
    };
  }
  if (headers) {
    config.headers = _.merge(config.headers, headers);
  }

  return axios.post(url, body, config)
    .finally((response) => {
      return response;
    });
}

export function putRequest(url, body, isSecure, headers) {
  let config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  if (isSecure) {
    config.headers = {
      "Authorization": sessionStorage.getItem('token'),
    };
  }
  if (headers) {
    config.headers = _.merge(config.headers, headers);
  }
  console.log(config);
  return axios.put(url, body, config)
    .finally((response) => {
      return response;
    });
}

export function getTitles() {

  let titles = getSimpleRequest(apiUrls.global.getNameTitles.url);

  return titles;
}

export function getCountries() {

  let countries = getSimpleRequest(apiUrls.global.getCountries.url);

  return countries;
}

export function getProperties() {
  let props = getSimpleRequest(apiUrls.properties.getAll.url);
  return props;
}

export function getPropertyById(id) {
  let props = getSimpleRequest(apiUrls.properties.getById.url + id);
  return props;
}

export function getPropertiesFulInfo() {
  let props = getSimpleRequest(apiUrls.properties.getAll.url);
  //alert(props[0]._id);
  //let test = '61f265d739c67100337ee796';
  //let fullProps = getSimpleRequest(apiUrls.properties.getAll2.url + test);
  return props;
}

export function getPropertiesSearch(fromToDate, adultsCount, childrenCount, searchName) {
  const words = fromToDate.split(' - ');
  let fromDate = formatDate(words[0]);
  let toDate = formatDate(words[1]);
  searchName = searchName.split(' ').join('%20');
  let params = '?from='+fromDate+'&to='+toDate+'&adults='+adultsCount+'&children='+childrenCount+'&neighborhood='+searchName;
  try {
    let props = getSimpleRequest(apiUrls.properties.getListingSearch.url + params);
    return props;
  }
  catch(err) {
    return 'Bad news';
  }

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }
}

export function getPropertyPrice(params) {
  // alert(params.listing_id);
  // let props = postRequest(apiUrls.properties.getPrice.url, params).then();
  // alert(props);
  // alert(JSON.stringify(props));
  // return props;
  let xhr = new XMLHttpRequest();

  xhr.open('POST', apiUrls.properties.getPrice.url, false);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(JSON.stringify(params));

  let response = JSON.parse(xhr.responseText);
  return response;
}



export function getSyncRequestGuesty(url) {
  let xhr = new XMLHttpRequest();

  xhr.open('GET', url, false);
  xhr.setRequestHeader('Authorization', 'Basic ZTliNTc5ZTFjY2MzN2U3NTIyNWZmYmExMTU5YTMyYjI6NGRjNDg0YWIwZjJiMzhiN2FmYjhmMzZjMzk2MzYzMmQ=');
  xhr.send();

  let response = JSON.parse(xhr.responseText);

  return response;

}

export function postSyncRequestGuesty(url, params) {
  let xhr = new XMLHttpRequest();

  xhr.open('POST', url, false);
  xhr.setRequestHeader('Authorization', 'Basic ZTliNTc5ZTFjY2MzN2U3NTIyNWZmYmExMTU5YTMyYjI6NGRjNDg0YWIwZjJiMzhiN2FmYjhmMzZjMzk2MzYzMmQ=');
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify(params));

  let response = JSON.parse(xhr.responseText);

  return response;

}

export function getRequestGuesty(url, params, headers) {
  let config = {
    headers: {
      "Authorization": 'Basic ZTliNTc5ZTFjY2MzN2U3NTIyNWZmYmExMTU5YTMyYjI6NGRjNDg0YWIwZjJiMzhiN2FmYjhmMzZjMzk2MzYzMmQ=',
    }
  };
  if (params) {
    config.params = params;
  }
  if (headers) {
    config.headers = _.merge(config.headers, headers);
  }

  return axios.get(url, config)
    .then((response) => {
      return response;
    });
}

export function postRequestGuesty(url, body, headers, multi=false) {

  const contentType = multi ? "application/json" : "multipart/form-data";

  let config = {
    headers: {
      "Content-Type": contentType,
      "Authorization": 'Basic ZTliNTc5ZTFjY2MzN2U3NTIyNWZmYmExMTU5YTMyYjI6NGRjNDg0YWIwZjJiMzhiN2FmYjhmMzZjMzk2MzYzMmQ=',
    }
  };

  if (headers) {
    config.headers = _.merge(config.headers, headers);
  }

  return axios.post(url, body, config)
    .finally((response) => {
      return response;
    });
}

export function putRequestGuesty(url, body, headers) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Basic ZTliNTc5ZTFjY2MzN2U3NTIyNWZmYmExMTU5YTMyYjI6NGRjNDg0YWIwZjJiMzhiN2FmYjhmMzZjMzk2MzYzMmQ=',
    }
  };
  if (headers) {
    config.headers = _.merge(config.headers, headers);
  }
  console.log(config);
  return axios.put(url, body, config)
    .finally((response) => {
      return response;
    });
}