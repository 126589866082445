import {createAction} from "../../factory/redux/action";

export const ACTION_TYPE = Object.freeze({
    SET_HOME_HEADER:"SET_HOME_HEADER",
    SET_OFFERS_HEADER:"SET_OFFERS_HEADER",
});

export const SET_HOME_HEADER = (payload) => createAction(
    ACTION_TYPE.SET_HOME_HEADER,
    payload
);

export const SET_OFFERS_HEADER = (payload) => createAction(
    ACTION_TYPE.SET_OFFERS_HEADER,
    payload
);