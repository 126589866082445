import {createAction} from "../../factory/redux/action";

export const ACTION_TYPE = Object.freeze({
    SET_RATES_DATE:"SET_RATES_DATE",
    SET_CALENDAR_VISIBLE: "SET_CALENDAR_VISIBLE",
    SET_START_DATE: "SET_START_DATE",
    SET_END_DATE: "SET_END_DATE",
    SET_LAST_DATE: "SET_LAST_DATE",
});

export const SET_RATES_DATE = (payload) => createAction(
    ACTION_TYPE.SET_RATES_DATE,
    payload
);

export const SET_CALENDAR_VISIBLE = (payload) => createAction(
    ACTION_TYPE.SET_CALENDAR_VISIBLE,
    payload
);

export const SET_START_DATE = (payload) => createAction(
    ACTION_TYPE.SET_START_DATE,
    payload
);

export const SET_END_DATE = (payload) => createAction(
    ACTION_TYPE.SET_END_DATE,
    payload
);


export const SET_LAST_DATE = (payload) => createAction(
    ACTION_TYPE.SET_LAST_DATE,
    payload
);