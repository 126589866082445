import {ACTION_TYPE} from "../actions/header";

const initialState = {
    home: true,
    offers: false
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_HOME_HEADER:
            return {
                ...state,
                home: action.payload,
            };

        case ACTION_TYPE.SET_OFFERS_HEADER:
            return {
                ...state,
                offers: action.payload,
            };
        default:
            return state;
    }
}