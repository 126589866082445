import {
    ACTION_TYPE,
    SET_CHOOSE_RATES_CONFIRM,
    SET_CHOOSE_RATES_CONFIRM_VISIBLE,
    SET_CHOOSE_RATES_IN_PROGRESS
} from "../actions/checkRatesPage";

const initialState = {
    stayPlanVisible: true,
    chooseTourVisible: false,

    inProgress: false,
    choosePackage: false,
    chooseProperty: true,

    chooseConfirmVisible: false,

    searchName: '',
    fromToDate: '',

    adultsCount: 2,
    childrenCount: 0,

    customChildren: 0,
    customAdults: 0,

    promoCode: '',
    money: {
        currency: 'USD',
        amount: 0,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_STAY_PLAN_VIEW:
            return {
                ...state,
                stayPlanVisible: action.payload,
            };

        case ACTION_TYPE.SET_CHOOSE_TOUR_VISIBLE:
            return {
                ...state,
                chooseTourVisible: action.payload,
            };

        case ACTION_TYPE.SET_CHOOSE_RATES_IN_PROGRESS:
            return {
                ...state,
                inProgress: action.payload,
            };
        case ACTION_TYPE.SET_SEARCH_RATES_DATE:
            return {
                ...state,
                fromToDate: action.payload,
            };
        case ACTION_TYPE.SET_SEARCH_RATES_NAME:
            return {
                ...state,
                searchName: action.payload,
            };
        case ACTION_TYPE.SET_CHOOSE_RATES_PROPERTY:
            return {
                ...state,
                chooseProperty: action.payload,
            };
        case ACTION_TYPE.SET_CHOOSE_RATES_PACKAGE:
            return {
                ...state,
                choosePackage: action.payload,
            };
        case ACTION_TYPE.SET_CHOOSE_RATES_CONFIRM_VISIBLE:
            return {
                ...state,
                chooseConfirmVisible: action.payload,
            };

        case ACTION_TYPE.SET_ADULTS_COUNT:
            return {
                ...state,
                adultsCount: action.payload,
            };

        case ACTION_TYPE.SET_CHILDREN_COUNT:
            return {
                ...state,
                childrenCount: action.payload,
            };

        case ACTION_TYPE.SET_PROMO_CODE:
            return {
                ...state,
                promoCode: action.payload,
            };
        case ACTION_TYPE.SET_PAYMENT:
            return {
                ...state,
                money: action.payload,
            };
        default:
            return state;
    }
}
