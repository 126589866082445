import {getRequest, postRequest} from "../../utils/requests";
import {apiUrls, appUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";
import {createError} from "../../utils/error";
import {getErrorCodeMessage} from "../../constants/apiErrorCode";
import {SET_CODE_INPUT} from "./showCodeInput";
import {CLEAR_STORE} from "./common";

export const ACTION_TYPE = Object.freeze({
  SET_IS_REGISTERED: "SET_IS_REGISTERED",

  SET_AUTHENTICATION_IN_PROCESS: "SET_AUTHENTICATION_IN_PROCESS",
  SET_ACCOUNT_DATA: "SET_ACCOUNT_DATA",
  SET_IS_AUTHENTICATED: "SET_IS_AUTHENTICATED",

  SET_REGISTRATION_ERROR: "SET_REGISTRATION_ERROR",
  SET_AUTHENTICATION_ERROR: "SET_AUTHENTICATION_ERROR",
});

const SET_ACCOUNT_DATA = (payload) => createAction(
  ACTION_TYPE.SET_ACCOUNT_DATA,
  payload
);

const SET_AUTHENTICATION_IN_PROCESS = (payload) => createAction(
  ACTION_TYPE.SET_AUTHENTICATION_IN_PROCESS,
  payload
);

const SET_IS_AUTHENTICATED = (payload) => createAction(
  ACTION_TYPE.SET_IS_AUTHENTICATED,
  payload
);

export const SET_IS_REGISTERED = (payload) => createAction(
  ACTION_TYPE.SET_IS_REGISTERED,
  payload
);

const SET_AUTHENTICATION_ERROR = (
  hasError,
  errorCode=null,
  message=null
) => createAction(
  ACTION_TYPE.SET_AUTHENTICATION_ERROR,
  createError(
    hasError,
    errorCode,
    message
  )
);
const SET_REGISTRATION_ERROR = (
  hasError,
  errorCode=null,
  message=null
) => createAction(
  ACTION_TYPE.SET_REGISTRATION_ERROR,
  createError(
    hasError,
    errorCode,
    message
  )
);

/**
 * Load authentication status and put it into store
 * @returns {Function}
 */
export const checkAuthentication = () => {
  return (dispatch, getState) => {
    dispatch(SET_AUTHENTICATION_IN_PROCESS(true));

    // fetchGet(apiUrls.authentication.status.url, true)
    //   .then(response => {
        if (
          sessionStorage.getItem("token")
          && sessionStorage.getItem("token").length > 10
          && sessionStorage.getItem("user")
          && sessionStorage.getItem("user").length > 0
        ) {
          dispatch(getUser(sessionStorage.getItem("user")))
          dispatch(SET_IS_AUTHENTICATED(true));
        } else {
          // dispatch common action to clear all stores
          // dispatch(CLEAR_STORE());
          dispatch(SET_IS_AUTHENTICATED(false));
          // dispatch(SET_ACCOUNT_DATA(null));
        }
      // })
      // .finally(() => {
      //   dispatch(SET_AUTHENTICATION_IN_PROCESS(false));
      // });
  }
};

export const registration = (regData) => {
  console.log(regData)
  return function (dispatch, getState) {
    console.log("reg3")
    postRequest(
      apiUrls.authentication.authenticate.url,
      regData,
    )
      .then((response) => {
        console.log(response)
        if (response.status < 300) {

          dispatch(SET_IS_REGISTERED(true));
        } else {
          dispatch(SET_REGISTRATION_ERROR(
            true,
            response.status,
            response.data
          ))
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch(SET_REGISTRATION_ERROR(
          true,
          error.response.status,
          getErrorCodeMessage(error.response.status),
        ))
      })
  }
}

export const signInRequest = (signInData) => {
  return function (dispatch, getState) {
    console.log("reg4")
    getRequest(
      apiUrls.authentication.codeRequest.url,
      signInData,
    )
      .then((response) => {
        console.log(response)
        if (response.status < 300) {
          console.log("if")
          dispatch(SET_CODE_INPUT(true));
          dispatch(SET_AUTHENTICATION_ERROR(
            false,
            null,
            null,
          ));
        } else {
          console.log(response)
          dispatch(SET_AUTHENTICATION_ERROR(
            true,
            response.status,
            response.data.message
          ))
        }
      })
      .catch((error) => {
        console.log(error)
        console.log(error.response)
        dispatch(SET_AUTHENTICATION_ERROR(
          true,
          error.response.status,
          error.response.data.message,
          // getErrorCodeMessage(error.response.status),
        ))
      })
  }
}

/**
 * Send authentication request to the server and write the result to the store
 * @param authData - authentication data
 * @returns {Function} with in redux-thunk specification
 */
export const authenticate = (code) => {
  /**
   *
   * @param dispatch - dispatch function to trigger actions
   * @param @getState - function to get current state
   */
  return (dispatch, getState) => {

    dispatch(SET_AUTHENTICATION_IN_PROCESS(true));
    dispatch(SET_AUTHENTICATION_ERROR(false));

    getRequest(
      apiUrls.authentication.codeVerify.url,
      code
    )
      .then((response) => {
        console.log(response)
        if (response.status < 300) {
          console.log(response)
          dispatch(SET_IS_AUTHENTICATED(true));
          sessionStorage.setItem('token', response.data.access_token);
          sessionStorage.setItem('user', response.data.user_id);
          dispatch(getUser(response.data.user_id))

          // dispatch(SET_ACCOUNT_DATA(response.data.user_id));

        } else {
          dispatch(SET_AUTHENTICATION_ERROR(
            true,
            response.error && response.error.code,
            // getErrorCodeMessage(response.error && response.error.code)
          ))
        }
      })
      .finally(() => {
        dispatch(SET_AUTHENTICATION_IN_PROCESS(false));
      });
  }
};

export const getUser = (userId) => {
  return (dispatch, getState) => {
    getRequest(
      apiUrls.user.get.url + userId,
      null,
      true,
    )
      .then((response) => {
        if (response.status < 300) {
          dispatch(SET_ACCOUNT_DATA(response.data));
        } else {
          dispatch(SET_AUTHENTICATION_ERROR(
            true,
            response.error && response.error.code,
            // getErrorCodeMessage(response.error && response.error.code)
          ))
        }
      })
      .finally(() => {
        dispatch(SET_AUTHENTICATION_IN_PROCESS(false));
      });
  }
}

export const signOut = () => {

  return (dispatch, getState) => {
    // getRequest(
    //   apiUrls.authentication.signOut.url,
    //   null,
    //   true,
    // )
    //   .then(response => {
    //     console.log(response);

    //     if (response.success) {

    //       // dispatch common action to clear all stores
    //       dispatch(CLEAR_STORE());

    //       // check authentication from server
    //       dispatch(checkAuthentication());

    //       // clear token
    //       sessionStorage.removeItem('token')

    //     }
    //   });
  };
};

// export function pingAuthentication() {
//   return function(dispatch, getState) {
//     return fetchPost(
//       apiUrls.authentication.isAuthenticated.url,
//       {}
//     );
//   }
// }