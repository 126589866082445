import {ACTION_TYPE} from "../actions/showWelcome";
import {SET_CODE_INPUT} from "../actions/showCodeInput";

const initialState = {
    visible: false
};

export default function reducer(state = initialState, action) {
    console.log('SET_CODE_INPUT1')
    console.log(action.type)

    if (action.type === 'SET_CODE_INPUT') {
      return {
        ...state,
        visible: action.payload,
      };
    } else {
      return state;
    }
    // switch (action.type) {
    //     case ACTION_TYPE.SET_CODE_INPUT: {
    //         console.log('SET_CODE_INPUT2')
    //         console.log(action.payload)
    //         return {
    //             ...state,
    //             visible: action.payload,
    //         };
    //     }
    //     default:
    //         return state;
    // }
}