import {createAction} from "../../factory/redux/action";
import {getReq} from "../../utils/requests";

//const titles = useSelector(state => state.globalData.titles);

export const ACTION_TYPE = Object.freeze({
    SET_TITLES: "SET_AUTHENTICATION_IN_PROCESS",
    SET_TITLES_IN_PROGRESS: "SET_TITLES_IN_PROGRESS",
});


const SET_TITLES = (payload) => createAction(
    ACTION_TYPE.SET_TITLES,
    payload
);

const SET_TITLES_IN_PROGRESS = (payload) => createAction(
    ACTION_TYPE.SET_TITLES_IN_PROGRESS,
    payload
);
