import {roles} from "./roles";

export const
    DEVELOPMENT_SERVER_URL = "https://tecventures-api.dev.symbicore.com";


export const appUrls = Object.freeze({
        landing: {url: `${process.env.PUBLIC_URL}/`, roles: [roles.all],},

        home: {
            name: "Home page",
            url: `${process.env.PUBLIC_URL}/`,
            roles: [roles.all],
        },

        comingSoon: {
            name: "Coming soon",
            url: `${process.env.PUBLIC_URL}/soon`,
            roles: [roles.all],
        },

        properties: {
            name: "links.properties",
            url: `${process.env.PUBLIC_URL}/properties`,
            roles: [roles.all],
        },
        experiences: {
            name: "links.experiences",
            url: `${process.env.PUBLIC_URL}/experiences`,
            roles: [roles.all],
        },
        rentalCars: {
            name: "links.rentalCars",
            url: `${process.env.PUBLIC_URL}/rental-cars`,
            roles: [roles.all],
        },
        privateJet: {
            name: "links.privateJet",
            url: `${process.env.PUBLIC_URL}/private-jets/home`,
            roles: [roles.all],
        },
        yachtCharter: {
            name: "links.yachtCharter",
            url: `${process.env.PUBLIC_URL}/yacht-charter`,
            roles: [roles.all],
        },
        weddings: {
            name: "links.weddings",
            url: `${process.env.PUBLIC_URL}/wedding/*`,
            roles: [roles.all],
        },
        weddingsHome: {
            name: "links.weddings",
            url: `${process.env.PUBLIC_URL}/wedding/home`,
            roles: [roles.all],
        },
        weddingsSpecialist: {
            name: "links.weddings",
            url: `${process.env.PUBLIC_URL}/wedding/specialist`,
            roles: [roles.all],
        },
        magazine: {
            name: "links.magazine",
            url: `${process.env.PUBLIC_URL}/magazine/*`,
            roles: [roles.all],
        },
        magazineHome: {
            name: "links.magazine",
            url: `${process.env.PUBLIC_URL}/magazine/home`,
            roles: [roles.all],
        },
        magazineVendors: {
            name: "links.magazine",
            url: `${process.env.PUBLIC_URL}/magazine/vendors`,
            roles: [roles.all],
        },
        magazineRestaurants: {
            name: "links.magazine",
            url: `${process.env.PUBLIC_URL}/magazine/restaurants`,
            roles: [roles.all],
        },
        magazineDiscover: {
            name: "links.magazine",
            url: `${process.env.PUBLIC_URL}/magazine/discover`,
            roles: [roles.all],
        },
        magazineArticles: {
            name: "links.magazine",
            url: `${process.env.PUBLIC_URL}/magazine/articles`,
            roles: [roles.all],
        },
        magazineAbout: {
            name: "links.magazine",
            url: `${process.env.PUBLIC_URL}/magazine/about`,
            roles: [roles.all],
        },

        magazineAllPhotos: {
            name: "links.magazine",
            url: `${process.env.PUBLIC_URL}/magazine/all-photos`,
            roles: [roles.all],
        },
        allProperties: {
            name: "links.allProperties",
            url: `${process.env.PUBLIC_URL}/properties`,
            roles: [roles.all],
        },
        myProfile: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/profile`,
            roles: [roles.all],
        },
        userProfile: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/user-profile/*`,
            roles: [roles.all],
        },
        userProfileHome: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/user-profile/home`,
            roles: [roles.all],
        },
        userProfileEdit: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/user-profile/edit`,
            roles: [roles.all],
        },
        userProfileBookings: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/user-profile/bookings`,
            roles: [roles.all],
        },
        userProfileBrowseExperiences: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/user-profile/experiences`,
            roles: [roles.all],
        },
        userProfileBusinessCenter: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/user-profile/buisiness-center`,
            roles: [roles.all],
        },
        userProfileChat: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/user-profile/chat`,
            roles: [roles.all],
        },
        userProfileViewItinerary: {
            name: "links.myProfile",
            url: `${process.env.PUBLIC_URL}/user-profile/itinerary`,
            roles: [roles.all],
        },

        more: {
            name: "links.more",
            url: `${process.env.PUBLIC_URL}/more`,
            roles: [roles.all],
        },

        aboutUs: {
            name: "links.aboutUs",
            url: `${process.env.PUBLIC_URL}/about-us/*`,
            roles: [roles.all],
        },
        aboutUsHome: {
            name: "links.aboutUs",
            url: `${process.env.PUBLIC_URL}/about-us/home`,
            roles: [roles.all],
        },
        aboutUsCorporateBios: {
            name: "links.aboutUs",
            url: `${process.env.PUBLIC_URL}/about-us/corporate-bios`,
            roles: [roles.all],
        },

        aboutUsLearnMore: {
            name: "links.aboutUs",
            url: `${process.env.PUBLIC_URL}/about-us/learn-more`,
            roles: [roles.all],
        },

        aboutUsCommitment: {
            name: "links.aboutUs",
            url: `${process.env.PUBLIC_URL}/about-us/commitment`,
            roles: [roles.all],
        },

        aboutUsHistory: {
            name: "links.aboutUs",
            url: `${process.env.PUBLIC_URL}/about-us/history`,
            roles: [roles.all],
        },

        aboutUsBioArticles: {
            name: "links.aboutUs",
            url: `${process.env.PUBLIC_URL}/about-us/bio-articles`,
            roles: [roles.all],
        },

        aboutUsHistoryArticles: {
            name: "links.aboutUs",
            url: `${process.env.PUBLIC_URL}/about-us/history-articles`,
            roles: [roles.all],
        },

        careers: {
            name: "links.careers",
            url: `${process.env.PUBLIC_URL}/careers`,
            roles: [roles.all],
        },
        contactUs: {
            name: "links.contactUs",
            url: `${process.env.PUBLIC_URL}/contact-us`,
            roles: [roles.all],
        },

        requestInvoice: {
            name: "links.requestInvoice",
            url: `${process.env.PUBLIC_URL}/request-invoice`,
            roles: [roles.all],
        },
        findReservation: {
            name: "links.findReservation",
            url: `${process.env.PUBLIC_URL}/find-reservation`,
            roles: [roles.all],
        },
        emailPreferences: {
            name: "links.emailPreferences",
            url: `${process.env.PUBLIC_URL}/email`,
            roles: [roles.all],
        },

        pressRoom: {
            name: "links.pressRoom",
            url: `${process.env.PUBLIC_URL}/news`,
            roles: [roles.all],
        },
        newOpenings: {
            name: "links.newOpenings",
            url: `${process.env.PUBLIC_URL}/news`,
            roles: [roles.all],
        },
        newsLetter: {
            name: "links.newsLetter",
            url: `${process.env.PUBLIC_URL}/news`,
            roles: [roles.all],
        },

        extraExperiences: {
            name: "links.extraExperiences",
            url: `${process.env.PUBLIC_URL}/extra-experiences`,
            roles: [roles.all],
        },
        residences: {
            name: "links.residences",
            url: `${process.env.PUBLIC_URL}/residence-clubs/*`,
            roles: [roles.all],
        },
        residencesHome: {
            name: "links.residences",
            url: `${process.env.PUBLIC_URL}/residence-clubs/home`,
            roles: [roles.all],
        },
        residencesView: {
            name: "links.residences",
            url: `${process.env.PUBLIC_URL}/residence-clubs/view-more-photos`,
            roles: [roles.all],
        },
        privateRetreats: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/*`,
            roles: [roles.all],
        },
        privateRetreatsHome: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/home`,
            roles: [roles.all],
        },

        privateRetreatsAmenities: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/amenities`,
            roles: [roles.all],
        },

        privateRetreatsDestinations: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/destinations`,
            roles: [roles.all],
        },
        privateRetreatsOffers: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/offers`,
            roles: [roles.all],
        },

        privateRetreatsOffersRates: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/offers-rates`,
            roles: [roles.all],
        },

        privateRetreatsDetails: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/property-details`,
            roles: [roles.all],
        },

        privateRetreatsMoreDetails: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/more-details`,
            roles: [roles.all],
        },
        privateRetreatsAccommodation: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/accommodation`,
            roles: [roles.all],
        },
        privateRetreatsContactUs: {
            name: "links.privateRetreats",
            url: `${process.env.PUBLIC_URL}/private-retreats/contact-us`,
            roles: [roles.all],
        },
        privateResidences: {
            name: "links.privateResidences",
            url: `${process.env.PUBLIC_URL}/private-residences/*`,
            roles: [roles.all],
        },
        privateResidencesHome: {
            name: "links.privateResidences",
            url: `${process.env.PUBLIC_URL}/private-residences/home`,
            roles: [roles.all],
        },
        privateResidencesInfo: {
            name: "links.privateResidences",
            url: `${process.env.PUBLIC_URL}/private-residences/info`,
            roles: [roles.all],
        },
        privateResidencesDetails: {
            name: "links.privateResidences",
            url: `${process.env.PUBLIC_URL}/private-residences/details`,
            roles: [roles.all],
        },

        giftCards: {
            name: "links.giftCards",
            url: `${process.env.PUBLIC_URL}/gift-cards/*`,
            roles: [roles.all],
        },

        giftCardsHome: {
            name: "GIFT CARDS HOME",
            url: `${process.env.PUBLIC_URL}/gift-cards/home`,
            roles: [roles.all],
        },

        globalPortfolio: {
            name: "links.globalPortfolio",
            url: `${process.env.PUBLIC_URL}/global-portfolio`,
            roles: [roles.all],
        },

        legalNotice: {
            name: "links.legalNotice",
            url: `${process.env.PUBLIC_URL}/legal-notice`,
            roles: [roles.all],
        },
        privacyPolicy: {
            name: "links.privacyPolicy",
            url: `${process.env.PUBLIC_URL}/privacy-policy`,
            roles: [roles.all],
        },
        cookiePref: {
            name: "links.cookiePref",
            url: `${process.env.PUBLIC_URL}/cookie`,
            roles: [roles.all],
        },
        accessPolicy: {
            name: "links.accessPolicy",
            url: `${process.env.PUBLIC_URL}/access-policy`,
            roles: [roles.all],
        },
        signIn: {
            name: "Sign In",
            url: `${process.env.PUBLIC_URL}/sign-in`,
            roles: [roles.all],
        },
        registration: {
            name: "Registration",
            url: `${process.env.PUBLIC_URL}/registration`,
            roles: [roles.all],
        },
        offers: {
            name: "Offers",
            url: `${process.env.PUBLIC_URL}/offers`,
            roles: [roles.all],
        },
        accommodations: {
            name: "Accommodations",
            url: `${process.env.PUBLIC_URL}/accommodations`,
            roles: [roles.all],
        },
        propertiesInfo: {
            name: "Properties information",
            url: `${process.env.PUBLIC_URL}/properties-information`,
            roles: [roles.all],
        },
        checkRates: {
            name: "check-rates",
            url: `${process.env.PUBLIC_URL}/check-rates`,
            roles: [roles.all],
        },

        resortOverview: {
            name: "resort overview",
            url: `${process.env.PUBLIC_URL}/resort-overview`,
            roles: [roles.all],
        },

        photosAndVideos: {
            name: "photos & videos",
            url: `${process.env.PUBLIC_URL}/photos-and-videos`,
            roles: [roles.all],
        },

        getawayAt: {
            name: "getaway at",
            url: `${process.env.PUBLIC_URL}/getaway-at`,
            roles: [roles.all],
        },

        covidUpdates: {
            name: "covid-19 updates",
            url: `${process.env.PUBLIC_URL}/getaway-at`,
            roles: [roles.all],
        },
        weddingsContactUs: {
            name: "Weddings Contact Us",
            url: `${process.env.PUBLIC_URL}/weddings-contact-us`,
            roles: [roles.all],
        },
        condos: {
            name: "Condos",
            url: `${process.env.PUBLIC_URL}/condos`,
            roles: [roles.all],
        },

        // privateResidence: {
        //     name: "PRIVATE RESIDENCES",
        //     url: `${process.env.PUBLIC_URL}/private-residences`,
        //     roles: [roles.all],
        // },

        servicesAndAmenities: {
            name: "SERVICES AND AMENITIES",
            url: `${process.env.PUBLIC_URL}/services-and-amenities`,
            roles: [roles.all],
        },

        history: {
            name: "links.history",
            url: `${process.env.PUBLIC_URL}/history`,
            roles: [roles.all],
        },
        historyInfo: {
            name: "links.history",
            url: `${process.env.PUBLIC_URL}/history-info`,
            roles: [roles.all],
        },
        historyBio: {
            name: "links.history",
            url: `${process.env.PUBLIC_URL}/history-bio`,
            roles: [roles.all],
        },

        restaurant: {
            name: "RESTAURANT",
            url: `${process.env.PUBLIC_URL}/restaurant`,
            roles: [roles.all],
        },

        location: {
            name: "LOCATION",
            url: `${process.env.PUBLIC_URL}/location`,
            roles: [roles.all],
        },

        featuredProperty: {
            name: "links.featuredProperty",
            url: `${process.env.PUBLIC_URL}/featured-property/:neighborId/`,
            roles: [roles.all],
        },

        spaWellness: {
            name: "SPA & WELLNESS",
            url: `${process.env.PUBLIC_URL}/spa`,
            roles: [roles.all],
        },
        golf: {
            name: "GOLF",
            url: `${process.env.PUBLIC_URL}/golf`,
            roles: [roles.all],
        },
        meetingsEvents: {
            name: "MEETINGS & EVENTS",
            url: `${process.env.PUBLIC_URL}/meetings-events`,
            roles: [roles.all],
        },
        poolsBeach: {
            name: "POOLS & BEACH",
            url: `${process.env.PUBLIC_URL}/pools-beach`,
            roles: [roles.all],
        },
        dining: {
            name: "DINING",
            url: `${process.env.PUBLIC_URL}/dining`,
            roles: [roles.all],
        },
        completeSuits: {
            name: "COMPLETE SUITES",
            url: `${process.env.PUBLIC_URL}/suits`,
            roles: [roles.all],
        },
        privateAviation: {
            name: "PRIVATE \n" +
                "AVIATION &\n" +
                "AIRPORT\n" +
                "LOUNGES",
            url: `${process.env.PUBLIC_URL}/aviation`,
            roles: [roles.all],
        },

        userLogin: {
            name: "SIGN IN",
            url: `${process.env.PUBLIC_URL}/user-sign-in`,
            roles: [roles.all],
        },

        userRegistration: {
            name: "REGISTRATION",
            url: `${process.env.PUBLIC_URL}/user-registration`,
            roles: [roles.all],
        },

        adminProfile: {
            name: "ADMIN PROFILE",
            url: `${process.env.PUBLIC_URL}/admin-profile/*`,
            roles: [roles.admin],
        },

        adminProfileHome: {
            name: "ADMIN PROFILE HOME",
            url: `${process.env.PUBLIC_URL}/admin-profile/home`,
            roles: [roles.admin],
        },

        adminProfileEdit: {
            name: "ADMIN PROFILE EDIT",
            url: `${process.env.PUBLIC_URL}/admin-profile/edit`,
            roles: [roles.admin],
        },

        adminProfileProperties: {
            name: "ADMIN PROFILE PROPERTIES",
            url: `${process.env.PUBLIC_URL}/admin-profile/properties`,
            roles: [roles.admin],
        },

        adminProfileCars: {
            name: "ADMIN PROFILE PROPERTIES",
            url: `${process.env.PUBLIC_URL}/admin-profile/cars`,
            roles: [roles.admin],
        },

        adminYachtCharters: {
            name: "ADMIN YACHT CHARTERS",
            url: `${process.env.PUBLIC_URL}/admin-profile/yacht-charters`,
            roles: [roles.admin],
        },

        adminCreateProperty: {
            name: "ADMIN CREATE PROPERTY",
            url: `${process.env.PUBLIC_URL}/admin-profile/create-property`,
            roles: [roles.admin],
        },

        adminProfileCarsEdit: {
            name: "ADMIN CREATE PROPERTY",
            url: `${process.env.PUBLIC_URL}/admin-profile/cars-edit`,
            roles: [roles.admin],
        },

        adminBookingPage: {
            name: "ADMIN BOOKING PAGE",
            url: `${process.env.PUBLIC_URL}/admin-profile/booking`,
            roles: [roles.admin],
        },

        adminUsers: {
            name: "ADMIN USERS PAGE",
            url: `${process.env.PUBLIC_URL}/admin-profile/users`,
            roles: [roles.admin],
        },

        adminEditContent: {
            name: "ADMIN EDIT CONTENT",
            url: `${process.env.PUBLIC_URL}/admin-profile/edit-content`,
            roles: [roles.admin],
        },

        adminOwnersStats: {
            name: "ADMIN CREATE PROPERTY",
            url: `${process.env.PUBLIC_URL}/admin-profile/owners-stats`,
            roles: [roles.admin],
        },

        adminStatsGuests: {
            name: "ADMIN CREATE PROPERTY",
            url: `${process.env.PUBLIC_URL}/admin-profile/stats-guests`,
            roles: [roles.admin],
        },

        adminEvents: {
            name: "ADMIN EVENTS",
            url: `${process.env.PUBLIC_URL}/admin-profile/events`,
            roles: [roles.admin],
        },

        adminExperiences: {
            name: "ADMIN experiences",
            url: `${process.env.PUBLIC_URL}/admin-profile/experiences`,
            roles: [roles.admin],
        },

        adminExperiencesEdit: {
            name: "ADMIN experiences edit",
            url: `${process.env.PUBLIC_URL}/admin-profile/experiences-edit`,
            roles: [roles.admin],
        },

        adminExperiencesCreate: {
            name: "ADMIN experiences create",
            url: `${process.env.PUBLIC_URL}/admin-profile/experiences-create`,
            roles: [roles.admin],
        },

        adminJet: {
            name: "ADMIN Jet Charter",
            url: `${process.env.PUBLIC_URL}/admin-profile/jet-charter`,
            roles: [roles.admin],
        },

        adminSettings: {
            name: "ADMIN SETTINGS",
            url: `${process.env.PUBLIC_URL}/admin-profile/settings`,
            roles: [roles.admin],
        },

        adminYachtAdd: {
            name: "ADMIN YACHT CHARTERS ADD",
            url: `${process.env.PUBLIC_URL}/admin-profile/yacht-add`,
            roles: [roles.admin],
        },
    })
;

export const apiUrls = Object.freeze({
    // authentication: {
    //     authenticate: {url: `${process.env.PUBLIC_URL.replace('tecventures','tecventures-api')}/user/signup`},
    //     authenticateAdmin: {url: `${process.env.PUBLIC_URL.replace('tecventures','tecventures-api')}/user/signupadmin`},
    //     codeRequest: {url: `${process.env.PUBLIC_URL.replace('tecventures','tecventures-api')}/user/signinrequest`},
    //     codeVerify: {url: `${process.env.PUBLIC_URL.replace('tecventures','tecventures-api')}/user/signinverify`},
    // },
    authentication: {
        authenticate: {url: `${DEVELOPMENT_SERVER_URL}/user/signup`},
        // authenticateAdmin: {url: `${process.env.PUBLIC_URL.replace('tecventures','tecventures-api')}/user/signupadmin`},
        codeRequest: {url: `${DEVELOPMENT_SERVER_URL}/user/signinrequest`},
        codeVerify: {url: `${DEVELOPMENT_SERVER_URL}/user/signinverify`},
        signOut: {url: `${DEVELOPMENT_SERVER_URL}/user/signout`},
    },
    user: {
        getAll: {url: `${DEVELOPMENT_SERVER_URL}/user/list`},
        get: {url: `${DEVELOPMENT_SERVER_URL}/user/get/`},
        update: {url: `${DEVELOPMENT_SERVER_URL}/user/update/`},
        getCard: {url: `${DEVELOPMENT_SERVER_URL}/user/getcard/`},
        updateCard: {url: `${DEVELOPMENT_SERVER_URL}/user/updatecard/`},
    },
    global: {
        getStatus: {url: `${process.env.PUBLIC_URL}/status`},
        getNameTitles: {url: `${DEVELOPMENT_SERVER_URL}/nametitles`},
        getCountries: {url: `${DEVELOPMENT_SERVER_URL}/countries`},
        getLocations: {url: `${DEVELOPMENT_SERVER_URL}/neighborhoods`},
    },
    properties: {
        getAll: {url: `${DEVELOPMENT_SERVER_URL}/listing/list`},
        get: {url: `${DEVELOPMENT_SERVER_URL}/listing/get/`},
        getAll2: {url: `${DEVELOPMENT_SERVER_URL}/listing/get/`},
        getListingSearch: {url: `${DEVELOPMENT_SERVER_URL}/listing/search`},
        getPrice: {url: `${DEVELOPMENT_SERVER_URL}/reservation/inquiry`},
    },

    reservation: {
        createReservationPayment: {url: `${DEVELOPMENT_SERVER_URL}/reservation/payment/`},
    },

    photos: {
        add: {url: `${DEVELOPMENT_SERVER_URL}/photo/add`},
        find: {url: `${DEVELOPMENT_SERVER_URL}/photo/find`},
        get: {url: `${DEVELOPMENT_SERVER_URL}/photo/get/`},
        update: {url: `${DEVELOPMENT_SERVER_URL}/photo/update/`},
    },

    guesty: {
        listing: {
            createListing: {url: "https://api.guesty.com/api/v2/listings"},
        }
    }
});