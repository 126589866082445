const errorCodes = Object.freeze({
  // Authentication
  422: "This user already exists",
  500: "Invalid phone or email",

  "default": "Error",
});

export function getErrorCodeMessage(errorCode){
  const errorText = errorCodes[errorCode];
  console.log('getErrorCodeMessage')
  console.log(errorText)
  if(!errorText) return errorCodes.default;
  else return errorText;
}