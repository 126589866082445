import {ACTION_TYPE} from "../actions/showWelcome";

const initialState = {
    visible: true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_WELCOME:
            return {
                ...state,
                visible: action.payload,
            };
        default:
            return state;
    }
}