import {ACTION_TYPE} from "../actions/showWelcome";

const initialState = {
  list: [],
  current: 1
};

export default function reducer(state = initialState, action) {
  console.log(action.payload)
  console.log(action.type)
  switch (action.type) {
    case 'SET_NEIGHBORHOODS':
      return {
        ...state,
        list: action.payload,
      };
    case 'SET_CURRENT_NEIGHBORHOOD':
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
}