import {getRequest, postRequest} from "../../utils/requests";
import {apiUrls} from "../../constants/urls";
import {createAction} from "../../factory/redux/action";
import {createError} from "../../utils/error";
import {getErrorCodeMessage} from "../../constants/apiErrorCode";
import {SET_CODE_INPUT} from "./showCodeInput";

export const ACTION_TYPE = Object.freeze({
  SET_NEIGHBORHOODS: "SET_NEIGHBORHOODS",
  SET_CURRENT_NEIGHBORHOOD: "SET_CURRENT_NEIGHBORHOOD",
});

const SET_NEIGHBORHOODS = (payload) => createAction(
  ACTION_TYPE.SET_NEIGHBORHOODS,
  payload
);
export const SET_CURRENT_NEIGHBORHOOD = (payload) => createAction(
  ACTION_TYPE.SET_CURRENT_NEIGHBORHOOD,
  payload
);


export const setNeighborhoods = () => {
  return function (dispatch, getState) {
    getRequest(
      apiUrls.global.getLocations.url,
    )
      .then((response) => {

        if(response.status < 300){
          dispatch(SET_NEIGHBORHOODS(response.data));
        }
      })
      .catch((error) => {
        console.log(error)
        console.log(error.response)
      })
  }
}
