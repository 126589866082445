import {createAction} from "../../factory/redux/action";
import {getRequest} from "../../utils/requests";
import {apiUrls} from "../../constants/urls";
import { useDispatch } from "react-redux";


export const ACTION_TYPE = Object.freeze({
    SET_USERS_DATA: "SET_USERS_DATA",
    SET_GET_USERS_ERROR: "SET_GET_USERS_ERROR",
})

export const SET_USERS_DATA = (payload) => createAction(
    ACTION_TYPE.SET_USERS_DATA,
    payload
);


export const getUsers = () => {
    return (dispatch, getState) => {
        getRequest(
            apiUrls.user.getAll.url,
            null,
            true,
        )
            .then((response) => {
                if (response.status < 300) {
                    dispatch(SET_USERS_DATA(response.data));
                    localStorage.setItem('ADMIN_PANEL_USERS',JSON.stringify(response.data));
                } else {
                    console.log("ERROR TO LOAD USERS: " + response.error);
                }
            })

    }
}