import {createAction} from "../../factory/redux/action";

export const ACTION_TYPE = Object.freeze({
    SET_STAY_PLAN_VIEW: "SET_STAY_PLAN_VIEW",
    SET_CHOOSE_TOUR_VISIBLE: "SET_CHOOSE_TOUR_VISIBLE",
    SET_CHOOSE_RATES_IN_PROGRESS: "SET_CHOOSE_RATES_IN_PROGRESS",
    SET_SEARCH_RATES_NAME: "SET_SEARCH_RATES_NAME",
    SET_SEARCH_RATES_DATE: "SET_SEARCH_RATES_DATE",
    SET_CHOOSE_RATES_PROPERTY: "SET_CHOOSE_RATES_PROPERTY",
    SET_CHOOSE_RATES_PACKAGE: "SET_CHOOSE_RATES_PACKAGE",
    SET_CHOOSE_RATES_CONFIRM_VISIBLE: "SET_CHOOSE_RATES_CONFIRM_VISIBLE",
    SET_ADULTS_COUNT: "SET_ADULTS_COUNT",
    SET_CHILDREN_COUNT: "SET_CHILDREN_COUNT",
    SET_PROMO_CODE: "SET_PROMO_CODE",
    SET_PAYMENT: "SET_PAYMENT",
});

export const SET_STAY_PLAN_VIEW = (payload) => createAction(
    ACTION_TYPE.SET_STAY_PLAN_VIEW,
    payload
);

export const SET_CHOOSE_TOUR_VISIBLE = (payload) => createAction(
    ACTION_TYPE.SET_CHOOSE_TOUR_VISIBLE,
    payload
);

export const SET_CHOOSE_RATES_IN_PROGRESS = (payload) => createAction(
  ACTION_TYPE.SET_CHOOSE_RATES_IN_PROGRESS,
  payload
);

export const SET_SEARCH_RATES_NAME = (payload) => createAction(
    ACTION_TYPE.SET_SEARCH_RATES_NAME,
    payload
);

export const SET_SEARCH_RATES_DATE = (payload) => createAction(
    ACTION_TYPE.SET_SEARCH_RATES_DATE,
    payload
);

export const SET_CHOOSE_RATES_PROPERTY = (payload) => createAction(
    ACTION_TYPE.SET_CHOOSE_RATES_PROPERTY,
    payload
);

export const SET_CHOOSE_RATES_PACKAGE = (payload) => createAction(
    ACTION_TYPE.SET_CHOOSE_RATES_PACKAGE,
    payload
);

export const SET_CHOOSE_RATES_CONFIRM_VISIBLE = (payload) => createAction(
    ACTION_TYPE.SET_CHOOSE_RATES_CONFIRM_VISIBLE,
    payload
);

export const SET_CHILDREN_COUNT = (payload) => createAction(
    ACTION_TYPE.SET_CHILDREN_COUNT,
    payload
);

export const SET_ADULTS_COUNT = (payload) => createAction(
    ACTION_TYPE.SET_ADULTS_COUNT,
    payload
);

export const SET_PROMO_CODE = (payload) => createAction(
    ACTION_TYPE.SET_PROMO_CODE,
    payload
);
export const SET_PAYMENT = (payload) => createAction(
  ACTION_TYPE.SET_PAYMENT,
  payload
);




