import React from 'react';
import {Redirect, Route as RouteOriginal} from 'react-router-dom';
import PropTypes from 'prop-types'
import {checkAccessRights} from "../../utils/authentication";
import {appUrls} from "../../constants/urls";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

export function Route({
                        component: Component,
                        exact,
                        path,
                        computedMatch,
                        roles,
                        ...props
                      }) {
  const authentication = useSelector((state) => state.authentication);
  const location = useLocation();

  if(checkAccessRights(authentication.accountData.admin, roles)) {
    console.log('continue');
    return (
        <RouteOriginal
            exact={exact}
            path={path}
            render={props => {
              return (
                  <Component {...props} />
              );
            }}
        />
    );
  } else {
    if (!authentication.isAuthenticated) {
      console.log('MATCHER: ' + computedMatch.url);
      console.log('MATCHER PATH: ' + path);
      return <Redirect to={appUrls.signIn.url + "?redirectUrl=" + computedMatch.url}/>;
    }
    console.log(exact);
    console.log(path);
    console.log(computedMatch);
    console.log(location.pathname);

    return <Redirect to={document.referrer}/>;
  }
}

Route.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,

  path: PropTypes.string,
  roles: PropTypes.array.isRequired,

};