import {ACTION_TYPE} from "../actions/users";

const initialState = {
    users: [],
    error: '',

};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_USERS_DATA:
            return {
                ...state,
                users: action.payload,
            };
        default:
            return state;
    }
}