import {ACTION_TYPE} from "../actions/searchInput";

function getCurrentLang() {
    if (localStorage.getItem('selectedLang') == null) {
        return 'EN';
    } else {
        return localStorage.getItem('selectedLang')
    }
}

const initialState = {
    currentLang: getCurrentLang(),
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_LANG:
            return {
                ...state,
                currentLang: action.payload,
            };
        default:
            return state;
    }
}