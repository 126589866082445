import thunk from "redux-thunk";
import authenticationMiddleware from "./authenticationMiddleware";


const middleware = [
  thunk,
  authenticationMiddleware,
];

export default middleware;
