import {ACTION_TYPE, SET_LAST_DATE} from "../actions/checkRatesDate";

const initialState = {
    date: '',
    startDate: '',
    endDate: '',
    lastDate: '',
    calendarVisible: true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_RATES_DATE:
            return {
                ...state,
                date: action.payload,
            };

        case ACTION_TYPE.SET_CALENDAR_VISIBLE:
            return {
                ...state,
                calendarVisible: action.payload,
            };

        case ACTION_TYPE.SET_START_DATE:
            return {
                ...state,
                startDate: action.payload,
            };

        case ACTION_TYPE.SET_END_DATE:
            return {
                ...state,
                endDate: action.payload,
            };
        case ACTION_TYPE.SET_LAST_DATE:
            return {
                ...state,
                lastDate: action.payload,
            };
        default:
            return state;
    }
}
