import {roles} from "../constants/roles";

/**
 * Check user rights
 * @param userRights - integer value of combined user roles
 * @param targetRights - list of separate roles for the user
 * @param ignoreNotDistinct - ignore roles which are not distinct
 * @returns {boolean}
 */
export const checkAccessRights = (
    userRights=roles.all.value,
    targetRights=[],
    ignoreNotDistinct = false,
) => {
  let grantAccess = false;

  // if anyone can access
  if(targetRights.includes(roles.all) && !ignoreNotDistinct){
    grantAccess = true;
  }

  // check for each role
  for (const targetRight of targetRights) {
    if(ignoreNotDistinct && !targetRight.distinct) {
      continue;
    }
    if((targetRight && targetRight.value) & userRights){
      grantAccess = true;
    }
  }
  console.log("access");
  console.log(grantAccess);

  return grantAccess;
};

