import React from "react";
import Masonry from 'react-masonry-css'
import "./ComingSoonPage.scss";
import logo from "./image/logo.png";
import l1 from "./image/L1.png";
import l2 from "./image/L2.png";
import r1 from "./image/R1.png";
import r2 from "./image/R2.png";
import pic1 from "./image/1.png";
import pic2 from "./image/2.png";
import pic3 from "./image/3.png";
import pic4 from "./image/4.png";
import pic5 from "./image/5.png";
import pic6 from "./image/6.png";
import pic7 from "./image/7.png";
import pic8 from "./image/8.png";
import pic9 from "./image/9.png";
import pic10 from "./image/10.png";
import pic11 from "./image/11.png";
import pic12 from "./image/12.png";
import pic13 from "./image/13.png";

const items = [
    {
        id: 1,
        classname: 'description',
        title: 'Launching Soon!',
        text: 'Conveniently plan, book, and manage your next luxury travel experience to Naples, Florida.',
        img: pic1
    },
    {
        id: 2,
        className: 'description',
        title: 'Property Management',
        text: 'Rest easy knowing your properties are managed by a trustworthy and reliable company.',
        img: pic2
    },
    {
        id: 3,
        className: 'description',
        title: 'Homes for Rent',
        text: 'Stay in the opulent comfort of a space that suits your needs. Our\n' +
            'listings span from beach villas to large family compounds.',
        img: pic3
    },
    {
        id: 4,
        className: 'description',
        title: 'Condos for Rent',
        text: ' Be awestruck with the view from your balcony with luxury \n' +
            'condo rentals in the heart of downtown Naples.',
        img: pic4
    },
    {
        id: 5,
        className: 'description',
        title: 'Multifamily Properties',
        text: 'Stay close to your family, team, or business colleagues \n' +
            'with an array of multifamily properties available for rent.',
        img: pic5
    },
    {
        id: 6,
        className: 'description',
        title: 'Experiences',
        text: 'Search and review highly-rated restaurants, shopping destinations, \n' +
            'events, and exciting activities to complement your stay.',
        img: pic6
    },
    {
        id: 7,
        className: 'description',
        title: 'Business Center',
        text: ' All Engaged Destinations guests have full access to our \n' +
            'business center located in Downtown Naples boasting Naples Bay Views.  Book a \n' +
            'private office or a conference room with the latest virtual meeting technology.',
        img: pic7
    },
    {
        id: 8,
        className: 'description',
        title: 'Meetings & Events',
        text: 'Efficiently plan your companies retreat, conferences, or \n' +
            'business meetings with our expertise of corporate event planning.',
        img: pic8
    },
    {
        id: 9,
        className: 'description',
        title: 'Rental Cars',
        text: 'Tastefully tour the city in a vehicle that fits your needs and appeals \n' +
            'to your style. From premium SUVs to sports cars, Engaged Rentals has what you \n' +
            'need to make your trip easy and enjoyable.',
        img: pic9
    },
    {
        id: 10,
        className: 'description',
        title: 'Private Jet',
        text: ' Travel in comfort, depart and arrive on your schedule with private jet \n' +
            'charter offerings by Engaged.',
        img: pic10
    },
    {
        id: 11,
        className: 'description',
        title: 'Yacht Charter',
        text: 'Cruise through the turquoise blue waters of the Gulf of Mexico or \n' +
            'the bay channels of Naples with a yacht at your disposal.',
        img: pic11
    },
    {
        id: 12,
        className: 'description',
        title: 'Weddings',
        text: 'Get the perfect mix of swoon and sophistication with the destination \n' +
            'wedding of your dreams. From reception venues to honeymoon packages, find \n' +
            'everything you need in one place.',
        img: pic12
    },
    {
        id: 13,
        className: 'description',
        title: 'Magazine',
        text: 'Need to fuel your wanderlust? Engaged Destinations Magazine will \n' +
            'keep you inspired as you plan your vacation.',
        img: pic13
    },
];

const breakpointColumnsObj = {
    default: 2,
    950: 1,
};

let currentPage = 1;
let timer;

class ComingSoonPage extends React.Component {


    componentDidMount = () => {
        timer = setInterval(() => {
            this.setNext();
        }, 10000);
    }

    setPrevious = () => {
        if (currentPage === 1) {
            currentPage = items.length;
        } else {
            currentPage--;
        }
        this.forceUpdate();
        clearInterval(timer);
        timer = setInterval(() => {
            this.setNext();
        }, 10000);
    }

    setNext = () => {
        if (currentPage === items.length) {
            currentPage = 1;
        } else {
            currentPage++;
        }
        this.forceUpdate();
        clearInterval(timer);
        timer = setInterval(() => {
            this.setNext();
        }, 10000);
    }

    render() {
        return (
            <div className={'background'} key={Math.random()} style={{
                width: '100vw',
                height: '100vh',
                margin: '0px auto',
                backgroundColor: 'rgba(0,0,0,0.2)',
                backgroundImage: `url(${items[currentPage - 1].img})`,
                backgroundBlendMode: 'darken',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
            }}>
                <div className={'soon-container'}>
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        <div className={'logo'}>
                            <Masonry
                                breakpointCols={2}
                                className="logo-grid"
                                columnClassName="logo-grid_column">
                                <div className={'logoImage'}>
                                    <img src={logo}/>
                                </div>
                                <div>
                                    <span className={'logoText'}>Engaged<br/>Destinations</span>
                                </div>
                            </Masonry>
                        </div>
                        <div className={'none'}/>
                        <div className={'soon'}>
                            <span>COMING SOON</span>
                        </div>
                        <div className={'description'}>
                            <h4 className={'article'}>{items[currentPage - 1].title}</h4>
                            <p className={'content'}>{items[currentPage - 1].text}</p>
                        </div>
                    </Masonry>
                    <div className="pagination">
                        <a href="#" className={'previous'} onClick={this.setPrevious}>
                            <img src={l1} className={'previousPoint'}/>
                            <img src={l2} className={'previousArrow'}/>
                        </a>
                        <a href="#" className={'next'} onClick={this.setNext}>
                            <img src={r2} className={'nextArrow'}/>
                            <img src={r1} className={'nextPoint'}/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

ComingSoonPage.propTypes = {};

export default ComingSoonPage;