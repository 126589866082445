import './App.css';
import ComingSoonPage from "./pages/comingSoon/ComingSoonPage";
import React from "react";
import {appUrls} from "./constants/urls";
import Loadable from 'react-loadable';
import {Switch} from "react-router-dom";
import {Route} from "./components/route/Route";
import {Loading} from "./components/loading/Loading";
import {useDispatch} from "react-redux";
import {authenticate, checkAuthentication} from "./redux/actions/authentication";
import {setNeighborhoods} from "./redux/actions/neighborhoods";

const components = [
    {
        url: appUrls.home.url,
        exact: true,
        roles: appUrls.home.roles,
        element: Loadable({
            loader: () => import('./pages/home/HomePage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.weddings.url,
        exact: true,
        roles: appUrls.weddings.roles,
        element: Loadable({
            loader: () => import('./pages/weddings/WeddingsPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.yachtCharter.url,
        exact: true,
        roles: appUrls.yachtCharter.roles,
        element: Loadable({
            loader: () => import('./pages/yatch/YatchPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.properties.url,
        exact: true,
        roles: appUrls.properties.roles,
        element: Loadable({
            loader: () => import('./pages/properties/AllPropertiesPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.signIn.url,
        exact: true,
        roles: appUrls.signIn.roles,
        element: Loadable({
            loader: () => import('./pages/signIn/SignInPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.registration.url,
        exact: true,
        roles: appUrls.registration.roles,
        element: Loadable({
            loader: () => import('./pages/registration/RegistrationPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.offers.url,
        exact: true,
        roles: appUrls.offers.roles,
        element: Loadable({
            loader: () => import('./pages/offers/OffersPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.propertiesInfo.url,
        exact: true,
        roles: appUrls.propertiesInfo.roles,
        element: Loadable({
            loader: () => import('./pages/propertiesInformation/PropertiesInformationPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.accommodations.url,
        exact: true,
        roles: appUrls.accommodations.roles,
        element: Loadable({
            loader: () => import('./pages/accommodations/AccommodationsPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.checkRates.url,
        exact: true,
        roles: appUrls.checkRates.roles,
        element: Loadable({
            loader: () => import('./pages/checkRatesPage/CheckRatesPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.experiences.url,
        exact: true,
        roles: appUrls.experiences.roles,
        element: Loadable({
            loader: () => import('./pages/experiencesPage/ExperiencesPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.weddingsContactUs.url,
        exact: true,
        roles: appUrls.weddingsContactUs.roles,
        element: Loadable({
            loader: () => import('./pages/weddingsContactUsPage/WeddingsContactUsPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.rentalCars.url,
        exact: true,
        roles: appUrls.rentalCars.roles,
        element: Loadable({
            loader: () => import('./pages/rentalCarsPage/RentalCarsPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.newOpenings.url,
        exact: true,
        roles: appUrls.newOpenings.roles,
        element: Loadable({
            loader: () => import('./pages/newOpenings/NewOpeningsPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.condos.url,
        exact: true,
        roles: appUrls.condos.roles,
        element: Loadable({
            loader: () => import('./pages/condosPage/CondosPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.legalNotice.url,
        exact: true,
        roles: appUrls.legalNotice.roles,
        element: Loadable({
            loader: () => import('./pages/legalNoticesPage/LegalNoticesPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.privacyPolicy.url,
        exact: true,
        roles: appUrls.privacyPolicy.roles,
        element: Loadable({
            loader: () => import('./pages/privacyNoticePage/PrivacyNoticePage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.accessPolicy.url,
        exact: true,
        roles: appUrls.accessPolicy.roles,
        element: Loadable({
            loader: () => import('./pages/accessibillityPolicyPage/AccessibillityPolicyPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.requestInvoice.url,
        exact: true,
        roles: appUrls.requestInvoice.roles,
        element: Loadable({
            loader: () => import('./pages/requestInvoicePage/RequestInvoicePage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.contactUs.url,
        exact: true,
        roles: appUrls.contactUs.roles,
        element: Loadable({
            loader: () => import('./pages/contactUsPage/ContactUsPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.emailPreferences.url,
        exact: true,
        roles: appUrls.emailPreferences.roles,
        element: Loadable({
            loader: () => import('./pages/emailSingUpPage/EmailSingUpPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.findReservation.url,
        exact: true,
        roles: appUrls.findReservation.roles,
        element: Loadable({
            loader: () => import('./pages/findReservationPage/FindReservationPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.photosAndVideos.url,
        exact: true,
        roles: appUrls.photosAndVideos.roles,
        element: Loadable({
            loader: () => import('./pages/photosVideosPage/PhotosVideosPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.residences.url,
        exact: true,
        roles: appUrls.residences.roles,
        element: Loadable({
            loader: () => import('./pages/residenceClubsPage/ResidenceClubsPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.privateResidences.url,
        exact: true,
        roles: appUrls.privateResidences.roles,
        element: Loadable({
            loader: () => import('./pages/privateResidencesPage/PrivateResidencesPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.servicesAndAmenities.url,
        exact: true,
        roles: appUrls.servicesAndAmenities.roles,
        element: Loadable({
            loader: () => import('./pages/servicesAndAmenitiesPage/ServicesAndAmenitiesPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.privateRetreats.url,
        exact: true,
        roles: appUrls.privateRetreats.roles,
        element: Loadable({
            loader: () => import('./pages/privateRetreatsPage/PrivateRetreatsPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.aboutUs.url,
        exact: true,
        roles: appUrls.aboutUs.roles,
        element: Loadable({
            loader: () => import('./pages/aboutUsPage/AboutUsPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.restaurant.url,
        exact: true,
        roles: appUrls.restaurant.roles,
        element: Loadable({
            loader: () => import('./pages/restaurantPage/RestaurantPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.location.url,
        exact: true,
        roles: appUrls.location.roles,
        element: Loadable({
            loader: () => import('./pages/locationPage/LocationPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.featuredProperty.url,
        exact: true,
        roles: appUrls.featuredProperty.roles,
        element: Loadable({
            loader: () => import('./pages/featuredProperty/FeaturedPropertyPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.userLogin.url,
        exact: true,
        roles: appUrls.userLogin.roles,
        element: Loadable({
            loader: () => import('./pages/userSignInPage/UserSignInPage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.userRegistration.url,
        exact: true,
        roles: appUrls.userRegistration.roles,
        element: Loadable({
            loader: () => import('./pages/userRegistrationPage/UserRegistrationPage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.myProfile.url,
        exact: true,
        roles: appUrls.myProfile.roles,
        element: Loadable({
            loader: () => import('./pages/profile/Profile'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.userProfile.url,
        exact: true,
        roles: appUrls.userProfile.roles,
        element: Loadable({
            loader: () => import('./pages/userProfilePage/UserProfilePage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.adminProfile.url,
        exact: true,
        roles: appUrls.adminProfile.roles,
        element: Loadable({
            loader: () => import('./pages/adminProfilePage/AdminProfilePage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.magazine.url,
        exact: true,
        roles: appUrls.magazine.roles,
        element: Loadable({
            loader: () => import('./pages/magazinePage/MagazinePage'),
            loading: Loading,
        }),
    },
    {
        url: appUrls.magazine.url,
        exact: true,
        roles: appUrls.magazine.roles,
        element: Loadable({
            loader: () => import('./pages/magazinePage/MagazinePage'),
            loading: Loading,
        }),
    },

    {
        url: appUrls.giftCards.url,
        exact: true,
        roles: appUrls.giftCards.roles,
        element: Loadable({
            loader: () => import('./pages/giftCardsPage/GiftCardsPage'),
            loading: Loading,
        }),
    },
]

function App() {
    const dispatch = useDispatch();
    dispatch(setNeighborhoods());
    dispatch(checkAuthentication());
    return (
        <div className={"App"}>
            <Switch>
                {
                    components.map((component, index) => (
                        <Route
                            key={index}
                            component={component.element}
                            exact={component.exact}
                            path={component.url}
                            roles={component.roles}
                        />
                    ))
                }
                <Route component={
                    Loadable({
                        loader: () => import('./pages/comingSoon/ComingSoonPage'),
                        loading: Loading,
                    })
                } roles={appUrls.comingSoon.roles}
                />
            </Switch>
        </div>
    );
}

export default App;

//I'm finishing my work day today. Can I disable the slack?
