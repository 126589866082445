import {ACTION_TYPE} from "../actions/authentication";

const initialState = {
  list: [{
    title: "",
    publicDescription: {
      summary: ""
    },
    pictures: {
      original: "",
    },
    address: {
      neighborhood: "",
      full: "",
    }
  }],
  current: {
    title: "",
    propertyType: "",
    roomType: "",
    accommodates: 1,
    bedrooms: 1,
    beds: 1,
    bathrooms: 1,
    publicDescription: {
      summary: "",
      space: "",
      access: "",
      neighborhood: "",
      transit: "",
      notes: ""
    },
    amenities: [],
    terms: {
      minNights: 1
    }
  },
  pricing: [
    {
      money: {
        currency: 'USD',
        amount: 10,
      },
      status: "",
    }
  ],
  error: {
    hasError: false,
    code: null,
    message: null,
  },
};

export default function reducer(state = initialState, action) {
  console.log(action.payload)
  console.log(action.type)
  switch (action.type) {
    case 'SET_LISTINGS':
      return {
        ...state,
        list: action.payload,
      };
    case 'SET_CURRENT_LISTING':
      return {
        ...state,
        current: action.payload,
      };
    case 'SET_PRICING':
      return {
        ...state,
        pricing: action.payload,
      };
    case 'SET_LISTING_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          ...action.payload,
        }
      };
    default:
      return state;
  }
}