import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/authentication";


const initialState = {
    isEdited: false,
    error: {
        hasError: false,
        code: null,
        message: null,
    },
    card: {
        las4: "",
        exp_month: "",
        exp_year: "",
    }
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case COMMON_ACTION_TYPE.CLEAR_STORE:
            return {
                ...initialState,
            };
        case ACTION_TYPE.SET_USER_EDITED:
            return {
                ...state,
                inProcess: action.payload,
            };
        case ACTION_TYPE.SET_USER_CARD:
            return {
                ...state,
                card: action.payload,
            };
        case ACTION_TYPE.SET_USER_EDIT_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    ...action.payload,
                }
            };
        case "SET_USER_CARD_ERROR": {
            console.log("ERROR")
            return {
                ...state,
                error: {
                    ...state.error,
                    ...action.payload,
                }
            };
        }
        default:
            return state;
    }
}
