import {ACTION_TYPE} from "../actions/bannerButton";

const initialState = {
    bannerButtonShow: false,
    bannerButtonText: "EXPLORE PROPERTIES",
    bannerButtonLink: ""
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_BUTTON_TEXT:
            return {
                ...state,
                bannerButtonText: action.payload,
            };
        case ACTION_TYPE.SET_BUTTON_SHOW:
            return {
                ...state,
                bannerButtonShow: action.payload,
            };
        case ACTION_TYPE.SET_BUTTON_LINK:
            return {
                ...state,
                bannerButtonLink: action.payload,
            };
        default:
            return state;
    }
}
