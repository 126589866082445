import {ACTION_TYPE} from "../actions/headerBanner";

const initialState = {
    header: "§§§§§§§§§§§§§§§§§§§§ Engaged! Destination",
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SET_BANNER_HEADER:
            return {
                ...state,
                header: action.payload,
            };
        default:
            return state;
    }
}