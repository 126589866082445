import {roles} from "../../constants/roles";
import {ACTION_TYPE as COMMON_ACTION_TYPE} from "../actions/common";
import {ACTION_TYPE} from "../actions/authentication";


const initialState = {
    inProcess: true,
    isAuthenticated: false,
    isRegistered: false,
    role: roles.all,
    accountData: {
        user_id: null,
        first_name: "",
        last_name: "",
        phone: 0,
        country_alpha: "",
    },
    error: {
        hasError: false,
        code: null,
        message: null,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case COMMON_ACTION_TYPE.CLEAR_STORE:
            return {
                ...initialState,
            };
        case ACTION_TYPE.SET_AUTHENTICATION_IN_PROCESS:
            return {
                ...state,
                inProcess: action.payload,
            };
        case ACTION_TYPE.SET_AUTHENTICATION_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    ...action.payload,
                }
            };
        case ACTION_TYPE.SET_REGISTRATION_ERROR:
            console.log("error");
            console.log(state.error);
            console.log(action.payload);

            return {
                ...state,
                error: {
                    ...state.error,
                    ...action.payload,
                }
            };
        case ACTION_TYPE.SET_ACCOUNT_DATA:
            return {
                ...state,
                accountData: {
                    ...(action.payload || initialState.accountData),
                },
                role: {
                    value: action.payload.admin,
                    name: action.payload.admin ? "Admin" : "Guest",
                }
            };
        case ACTION_TYPE.SET_IS_AUTHENTICATED:
            let isAuthenticated = action.payload;
            return {
                ...state,
                isAuthenticated,
                accountData: {
                    // if is authenticated - keep accountData, otherwise - clear it
                    ...(isAuthenticated ? state.accountData : initialState.accountData),
                },
            };
        case ACTION_TYPE.SET_IS_REGISTERED:
            console.log(action)
            return {
                ...state,
                isRegistered: action.payload,
            };
        default:
            return state;
    }
}
