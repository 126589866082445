import {combineReducers} from "redux";
import headerBanner from "./headerBanner"
import bannerButton from "./bannerButton"
import searchInput from "./searchInput"
import selectLang from "./selectLang"
import checkRatesDate from "./checkRatesDate"
import checkRatesPage from "./checkRatesPage"
import myProfile from "./myProfile"
import showWelcome from "./showWelcome"
import showReservation from "./showReservation"
import showCreateProfile from "./showCreateProfile"
import header from "./header"
import globalData from "./globalData"
import authentication from "./authentication"
import showCodeInput from "./showCodeInput"
import user from "./user"
import users from "./users"
import neighborhoods from "./neighborhoods"
import listing from "./listing"

const reducer = combineReducers({
    listing,
    neighborhoods,
    user,
    users,
    showCodeInput,
    authentication,
    headerBanner,
    bannerButton,
    searchInput,
    selectLang,
    checkRatesDate,
    myProfile,
    showWelcome,
    showReservation,
    showCreateProfile,
    checkRatesPage,
    header,
    globalData,
});

export default reducer;